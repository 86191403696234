import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Button from '../components/Button';
import { Link } from '@reach/router';

const SuccessPage = () => (
  <Layout>
    <SEO
      title="Brian Kulp Co. | Thanks for reaching out!"
      keywords={[`brian kulp`, `seattle`, `designer`, `developer`]}
    />

    <div className="success">
      <div className="success__thumbs-up">
        <span role="img" aria-label="Thumbs up!">
          👍
        </span>
      </div>
      <h2 className="success__title">Thanks for reaching out</h2>
      <h2 className="success__subtitle">
        I typically reply within 1–2 business days. Talk soon!
      </h2>

      <Link to="/">
        <Button tabIndex="-1">Go home</Button>
      </Link>
    </div>
  </Layout>
);

export default SuccessPage;
